.customCard-contanier{
    width: 16rem;
    border-radius: 0.5rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    transition: box-shadow 0.3s ease;
    cursor: pointer; 
    margin: 0.5rem;
    background-color: #FFFFFF;
    height: fit-content;
}
.customCard-img{
    width: 100%;
    border-radius: 0.5rem 0.5rem 0 0;
}
.customCard-title{
    margin: 0.5rem 5%;
    padding-top: 0.5rem;
}
.category{
    font-family: fantasy;
    font-size: 0.8rem;
    margin-top: 3rem;
}
.customCard-category{
    margin: 0.5rem 5%;
}

.customCard-contanier:hover{
    box-shadow: 0px 2px 10px #3b82f6;
}
.category-pill{
    background-color: #e2e8f0;
    border: none;
    padding: 5px 15px;
    text-align: center;
    margin: 4px 4px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 16px;
}





