.view-btn{
    background-color: #22c55e;
    border: none;
    color: white;
    padding: 0.2rem 1rem 0.2rem;
    /* padding-left: 1rem; */
    /* padding-right: 1rem; */
    text-align: center;
    text-decoration: none;
    /* display: inline-block; */
    margin: 1px;
    cursor: pointer;
    border-radius: 22px;
}