.card-contanier {
    width: 21.5rem;
    border-radius: 0.5rem;
    box-shadow: 0px 5px 10px #e2e8f0;
    transition: box-shadow 0.3s ease;
    cursor: pointer;
    margin: 0.5rem;
    background-color: #FFFFFF;
    height: fit-content;
}

.card-img {
    width: 100%;
    border-radius: 1rem 1rem 0 0;
}

.card-title{
    margin: 0.5rem 5%;
    padding-top: 0.5rem;
}
.card-description{
    margin: 0.5rem 5%;
}

.card-contanier:hover {
    box-shadow: 0px 2px 10px #3b82f6;
}