.boxShadow{
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.5s ease;
    cursor: pointer;
}
.boxShadow:hover {
    box-shadow: 0px 2px 10px #4ade80;
}

.list-startup{
    background-color: #4ade80;
    /* border: none; */
    color: white;
    padding: 5px;
    /* text-align: center; */
    /* text-decoration: none; */
    /* display: inline-block; */
    margin: 4px;
    cursor: pointer;
    border-radius: 16px;
}